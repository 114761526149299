:root {
  /* Typography */
  --font-family-sans-serif:      'Roboto', sans-serif;
  --font-family-base: var(--font-family-sans-serif);
  --font-family-monospace: 'SFMono-Regular', 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New', 'monospace';

  /* Boxed Metric Theme */
  --boxed-metric-bg: var(--background-secondary);
  --boxed-metric-text: var(--text-primary);
  --boxed-metric-secondary-text: var(--text-secondary);
  --boxed-metric-border: var(--border-color);
  --boxed-metric-hover-bg: var(--hover-color);
  --boxed-metric-icon-color: var(--text-primary);
  --boxed-metric-background: var(--background-secondary);

  /* Metric Gradients */
  --metric-red-gradient: linear-gradient(45deg, #ff6b6b, #ff8787);
  --metric-violet-gradient: linear-gradient(45deg, #845ef7, #9775fa);
  --metric-blue-gradient: linear-gradient(45deg, #339af0, #4dabf7);
  --metric-greyblue-gradient: linear-gradient(45deg, #495057, #868e96);
  --metric-yellow-gradient: linear-gradient(45deg, #ffd43b, #fcc419);
  --metric-dark-gradient: var(--background-secondary);

  /* Metric Text Colors */
  --metric-gradient-text: #ffffff;
  --metric-secondary-text: #ffffff;
  --metric-text: var(--text-primary);

  /* BoxedMetric Colors */
  --metric-red-light: #fff5f5;
  --metric-red-dark: #2d2235;
  --metric-yellow-light: #fffbeb;
  --metric-yellow-dark: #2d2830;
  --metric-blue-light: #f5f8ff;
  --metric-blue-dark: #252840;
  --metric-green-light: #f0fff4;
  --metric-green-dark: #253028;

  /* PerformanceIndex Theme */
  --performance-text-primary: var(--text-primary);
  --performance-text-secondary: var(--text-secondary);
  --performance-divider: var(--border-color);
  --performance-card-bg: var(--background-secondary);

  /* SocialMedia Posts Table colors */
  --social-media-table-primary: #107CA1;
  --social-media-table-secondary: #ECBC32;

  --metric-positive: #01A599;
  --metric-negative: #DA5265;

}

:root[data-theme="light"] {
  --background-body: #fafaff;
  --background-primary: #ffffff;
  --background-secondary: #eef0f1;
  --background-lighter: #f2f2f2;
  --background-card: var(--background-primary);
  --background-tab-active: #6c757d;
  --text-primary: #212529;
  --text-secondary: #6c757d;
  --border-color: #9399a0;
  --accent-color: #0d6efd;
  --accent-color-rgb: 13, 110, 253;
  --hover-color: #f8f9fa;
  --shadow-color: rgba(0, 0, 0, 0.1);

  --heatmap-min: #ffffff;
  --heatmap-max: #ecbc32;
  --heatmap-null: var(--background-lighter);
  --heatmap-hover: #e0d2d2;
  
  /* Additional colors */
  --success-color: #28a745;
  --warning-color: #ffc107;
  --danger-color: #dc3545;
  --info-color: #17a2b8;
  
  /* Graph colors */
  --graph-line: #0d6efd;
  --graph-grid: #e9ecef;
  --graph-text: #6c757d;
  
  /* Specific component colors */
  --metric-box-bg: #ffffff;
  --metric-box-border: #e9ecef;
  --table-header-bg: #f8f9fa;
  --table-row-hover: #f2f2f2;
  
  /* Theme button colors */
  --theme-btn-bg: #117ca1;
  --theme-btn-text: #ffffff;
  --theme-btn-hover-bg: #117ca1;
  --theme-btn-hover-text: var(--background-primary);
  --theme-btn-border-color: #117ca1;

  --theme-icon-color: #212529;

  /* Dropdown menu colors */
  --dropdown-item-hover: #117ca1; 
  
  /* Topbar colors */
  --topbar-bg: #ffffff;
  --topbar-text: #505050;
  --topbar-icon: #c6c6c6;
  --toggle-btn-bg: #c6c6c6;
  --toggle-btn-color: var(--text-primary);
  --toggle-btn-border: #c0c0c0;
  --toggle-btn-hover-bg: #e9ecef;
  --toggle-btn-hamburger: #ffffff;
  
  /* Dashboard colors */
  --dashboard-bg: #ffffff;
  --dashboard-text: #505050;
  --dashboard-loader-bg: #f8f9fa;
  --dashboard-title: #212529;
  --dashboard-subtitle: #6c757d;
  
  /* Person Overview colors */
  --profile-border: #377B9E;
  --profile-edit-bg: #377B9E;
  --profile-edit-icon: #ffffff;
  --profile-name: #212529;
  --profile-title: #6c757d;
  
  /* BoxedMetric gradients */
  --metric-red-gradient: linear-gradient(to bottom right, #783550, #4F2033);
  --metric-violet-gradient: linear-gradient(to bottom right, #373064, #2A2549);
  --metric-blue-gradient: linear-gradient(to bottom right, #387A9E, #1F4A61);
  --metric-greyblue-gradient: linear-gradient(to bottom right, #4C7084, #343147);
  --metric-yellow-gradient: linear-gradient(to bottom right, #E4BE50, #806412);
  --metric-dark-gradient: #2b283b;
  
  /* BoxedMetric colors */
  --metric-text: #ffffff;
  --metric-secondary-text: rgba(255, 255, 255, 0.8);
  --metric-title-text: #ffffff;
  --metric-value-text: #ffffff;
  --metric-icon-color: #212529;
  --metric-background: #f8f9fa;
  
  /* BoxedMetric gradients - text colors override */
  --metric-gradient-text: #ffffff;
  
  /* SocialMediaMetric colors */
  --social-metric-bg: #f8f9fa;
  --social-metric-icon: #377B9E;
  --social-metric-title: #212529;
  --social-metric-subtitle: #495057;
  --social-metric-value: #212529;
  --social-metric-growth-positive: #198754;
  --social-metric-growth-negative: #dc3545;
  
  /* Social Timeline Theme */
  --timeline-bg: var(--background-secondary);
  --timeline-card-bg: var(--background-secondary);
  --timeline-card-border: var(--border-color);
  --timeline-text: var(--text-primary);
  --timeline-secondary-text: var(--text-secondary);
  --timeline-link: var(--accent-color);
  --timeline-stat-bg: var(--background-primary);
  --timeline-stat-text: var(--text-primary);
  --timeline-icon-bg: var(--background-secondary);
  --timeline-hover-bg: var(--hover-color);
  --timeline-shadow: var(--card-shadow);
  --timeline-title-hover: var(--accent-color);
  
  /* Card and shadow styles */
  --card-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  --card-hover-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  /* BoxedMetric Theme - Light Mode */
  --metric-red-bg: var(--metric-red-light);
  --metric-yellow-bg: var(--metric-yellow-light);
  --metric-blue-bg: var(--metric-blue-light);
  --metric-green-bg: var(--metric-green-light);
  
  /* PerformanceIndex Theme - Light Mode */
  --performance-gauge-background: #f0f0f0;
  --performance-gauge-color: #E4BE50;
  --performance-card-bg: #ffffff;
}

:root[data-theme="dark"] {
  --background-body: #201D31;
  --background-primary: #201D31;
  --background-secondary: #2b283b;
  --background-lighter: #33314a;
  --background-card: var(--topbar-bg);
  --background-tab-active: var(--background-secondary);
  --text-primary: #e9ecef;
  --text-secondary: #adb5bd;
  --border-color: #5f5f5f;
  --hover-color: #2d2d2d;
  --shadow-color: rgba(0, 0, 0, 0.2);
  --accent-color: #e9ecef;
  --accent-color-rgb: 233, 236, 239;


  --heatmap-min: #ffffff;
  --heatmap-max: #ecbc32;
  --heatmap-null: var(--background-lighter);
  --heatmap-hover: #d0d9e1;
  
  /* BoxedMetric gradients */
  
  /* BoxedMetric Text Colors - Dark Mode */
  --metric-text: #ffffff;
  --metric-secondary-text: rgba(255, 255, 255, 0.8);
  
  /* BoxedMetric Theme - Dark Mode */
  --boxed-metric-background: #252235;
  --boxed-metric-border: #363146;
  --boxed-metric-hover: #2a2740;
  
  /* Additional colors */
  --success-color: #2ea043;
  --warning-color: #d29922;
  --danger-color: #da3633;
  --danger-text: white;
  --info-color: #2188ff;
  
  /* Graph colors */
  --graph-line: #3d8bfd;
  --graph-grid: #404040;
  --graph-text: #adb5bd;
  
  /* Specific component colors */
  --metric-box-bg: #2d2d2d;
  --metric-box-border: #404040;
  --table-header-bg: #1a1a1a;
  --table-row-hover: #363636;
  
  /* Theme button colors */
  --theme-btn-bg: #117ca1;
  --theme-btn-text: #ffffff;
  --theme-btn-hover-bg: #117ca1;
  --theme-btn-hover-text: var(--text-primary);
  --theme-btn-border-color: #117ca1;
  
  --theme-icon-color: #e9ecef;

  /* Dropdown menu colors */
  --dropdown-item-hover: #117ca1; 
  
  /* Topbar colors */
  --topbar-bg: #2B283B;
  --topbar-text: #ffffff;
  --topbar-icon: #2A2839;
  --toggle-btn-bg: var(--background-secondary);
  --toggle-btn-color: var(--text-primary);
  --toggle-btn-border: var(--border-color);
  --toggle-btn-hover-bg: var(--background-primary);
  --toggle-btn-hamburger: #E4BE50;
  
  /* Dashboard colors */
  --dashboard-bg: #201D31;
  --dashboard-text: #ffffff;
  --dashboard-loader-bg: #302D41;
  --dashboard-title: #ffffff;
  --dashboard-subtitle: #adb5bd;
  
  /* Person Overview colors */
  --profile-border: #377B9E;
  --profile-edit-bg: #377B9E;
  --profile-edit-icon: #ffffff;
  --profile-name: #ffffff;
  --profile-title: #adb5bd;
  
  /* BoxedMetric gradients */
  --metric-red-gradient: linear-gradient(to bottom right, #783550, #4F2033);
  --metric-violet-gradient: linear-gradient(to bottom right, #373064, #2A2549);
  --metric-blue-gradient: linear-gradient(to bottom right, #387A9E, #1F4A61);
  --metric-greyblue-gradient: linear-gradient(to bottom right, #4C7084, #343147);
  --metric-yellow-gradient: linear-gradient(to bottom right, #E4BE50, #806412);
  --metric-dark-gradient: #2b283b;
  
  /* BoxedMetric colors */
  --metric-text: #ffffff;
  --metric-title-text: #ffffff;
  --metric-value-text: #e9ecef;
  --metric-icon-color: #ffffff;
  --metric-background: #252235;
  
  /* BoxedMetric gradients - text colors override */
  --metric-gradient-text: #ffffff;
  
  /* SocialMediaMetric colors */
  --social-metric-bg: #2b283b;
  --social-metric-icon: #377B9E;
  --social-metric-title: #ffffff;
  --social-metric-subtitle: #e9ecef;
  --social-metric-value: #ffffff;
  --social-metric-growth-positive: #198754;
  --social-metric-growth-negative: #dc3545;
  
  /* Social Timeline Theme */
  --timeline-bg: var(--background-secondary);
  --timeline-card-bg: var(--background-secondary);
  --timeline-card-border: var(--border-color);
  --timeline-text: var(--text-primary);
  --timeline-secondary-text: var(--text-secondary);
  --timeline-link: var(--accent-color);
  --timeline-stat-bg: var(--background-primary);
  --timeline-stat-text: var(--text-primary);
  --timeline-icon-bg: var(--background-secondary);
  --timeline-hover-bg: var(--hover-color);
  --timeline-shadow: var(--card-shadow);
  --timeline-title-hover: var(--accent-color);
  
  /* Card and shadow styles */
  --card-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  --card-hover-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  
  /* BoxedMetric Theme - Dark Mode */
  --metric-red-bg: var(--metric-red-dark);
  --metric-yellow-bg: var(--metric-yellow-dark);
  --metric-blue-bg: var(--metric-blue-dark);
  --metric-green-bg: var(--metric-green-dark);
  
  /* PerformanceIndex Theme - Dark Mode */
  --performance-gauge-background: #555361;
  --performance-gauge-color: #E4BE50;
  --performance-card-bg: var(--background-secondary);
}

/* Base styles */
body {
  background-color: var(--background-primary);
  color: var(--text-primary);
  font-family: var(--font-family-base);
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Common element styles */
button {
  background-color: var(--background-secondary);
  color: var(--text-primary);
  border-color: var(--border-color);
}

input, textarea, select {
  background-color: var(--background-secondary);
  color: var(--text-primary);
  border-color: var(--border-color);
}

/* Add more theme-specific styles as needed */
