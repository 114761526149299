.progress-bar {
	&.bg-positive {
		background-color: #02a499;
	}

	&.bg-neutral {
		background-color: #a09ea8;

		// background-color: #727174;
	}

	&.bg-negative {
		background-color: #ec4561;
	}
}
